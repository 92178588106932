import React from "react";

import { Container, Row, Col } from "reusecore/Layout";
import SectionTitle from "reusecore/SectionTitle";

import Icon1 from "assets/images/hosting/features/01.svg";
import Icon2 from "assets/images/hosting/features/02.svg";
import Icon3 from "assets/images/hosting/features/03.svg";
import NotificationIcon from "assets/images/hosting/features/notification.svg";
import AuthenticatorIcon from "assets/images/hosting/features/authenticator.svg";
import ApprovalReqIcon from "assets/images/hosting/features/approvalreq.svg";
import TodoIcon from "assets/images/hosting/features/todo.svg";
import ExpenseIcon from "assets/images/hosting/features/expense.svg";
import TimesheetIcon from "assets/images/hosting/features/timesheet.svg";
import LeaveIcon from "assets/images/hosting/features/leave.svg";
import SurveyIcon from "assets/images/hosting/features/survey.svg";
import ContactIcon from "assets/images/hosting/features/contact.svg";
import AnalyticsIcon from "assets/images/hosting/features/analytics.svg";

import ModulesSectionWrapper from "./modules.style";

const Modules = () => {
    return (
        <ModulesSectionWrapper id="modules">
            <Container fullWidthSM>
                <SectionTitle className="section-title" UniWidth="100%">
                    {/* <h4>Modules</h4> */}
                    <h2>
                        The Modules in MyCorp
                </h2>
                </SectionTitle>
                <Row>
                    <Col xs={12} sm={6} md={4}>
                        <div className="fetures-block">
                            <div className="fetures-icon-block">
                                <img src={NotificationIcon} alt="img" />
                            </div>
                            <div className="fetures-content-block">
                                <h3>Notification</h3>
                                <p>
                                    In-App notification, group broadcast, direct notification and SMS text message forwarding.
                                </p>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} sm={6} md={4}>
                        <div className="fetures-block">
                            <div className="fetures-icon-block">
                                <img src={AuthenticatorIcon} alt="img" />
                            </div>
                            <div className="fetures-content-block">
                                <h3>Authenticator</h3>
                                <p>
                                    In-App end to end encrypted authentication and authorization, more secure than traditional SMS.
                                </p>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} sm={6} md={4}>
                        <div className="fetures-block">
                            <div className="fetures-icon-block">
                                <img src={ApprovalReqIcon} alt="img" />
                            </div>
                            <div className="fetures-content-block">
                                <h3>Approval-Req</h3>
                                <p>
                                    In-App Approval request system with tracking and search.
                                </p>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} sm={6} md={4}>
                        <div className="fetures-block">
                            <div className="fetures-icon-block">
                                <img src={TodoIcon} alt="img" />
                            </div>
                            <div className="fetures-content-block">
                                <h3>ToDo</h3>
                                <p>
                                    Assign and track tasks/to-do with workflow and tracking.
                                </p>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} sm={6} md={4}>
                        <div className="fetures-block">
                            <div className="fetures-icon-block">
                                <img src={ExpenseIcon} alt="img" />
                            </div>
                            <div className="fetures-content-block">
                                <h3>Expense</h3>
                                <p>
                                    Expense claim system with workflow, reporting and approval.
                                </p>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} sm={6} md={4}>
                        <div className="fetures-block">
                            <div className="fetures-icon-block">
                                <img src={TimesheetIcon} alt="img" />
                            </div>
                            <div className="fetures-content-block">
                                <h3>Timesheet</h3>
                                <p>
                                    Real time location/QR based user check-in and check-out with timesheet reporting and approval.
                                </p>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} sm={6} md={4}>
                        <div className="fetures-block">
                            <div className="fetures-icon-block">
                                <img src={LeaveIcon} alt="img" />
                            </div>
                            <div className="fetures-content-block">
                                <h3>Leave</h3>
                                <p>
                                    Leave application with workflow, tracking and approval system
                                </p>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} sm={6} md={4}>
                        <div className="fetures-block">
                            <div className="fetures-icon-block">
                                <img src={SurveyIcon} alt="img" />
                            </div>
                            <div className="fetures-content-block">
                                <h3>Survey</h3>
                                <p>
                                    Survey management system with basic analytics.
                                </p>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} sm={6} md={4}>
                        <div className="fetures-block">
                            <div className="fetures-icon-block">
                                <img src={ContactIcon} alt="img" />
                            </div>
                            <div className="fetures-content-block">
                                <h3>Contact</h3>
                                <p>
                                    Customer/client contact database with individual, group and organization management plus tagging.
                                </p>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} sm={6} md={4}>
                        <div className="fetures-block">
                            <div className="fetures-icon-block">
                                <img src={AnalyticsIcon} alt="img" />
                            </div>
                            <div className="fetures-content-block">
                                <h3>Analytics</h3>
                                <p>
                                    Advanced Analytics for all Mycorp Modules.
                                </p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </ModulesSectionWrapper>
    );
};

export default Modules;
