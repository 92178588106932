import React from "react";
import { ThemeProvider } from 'styled-components';

import Layout from "../components/layout"
import SEO from "../components/seo"

import Navigation from 'sections/Navigation';
import Banner from 'sections/Banner-default';
import About from 'sections/About-classic';
import Features from 'sections/Features';
import Faq from 'sections/Faq';
import Integrations from 'sections/Integrations';
import AppScreens from 'sections/AppScreens';
import Counters from 'sections/Counters';
import Pricing from 'sections/Pricing';
import Testimonial from 'sections/Testimonial';
import News from 'sections/News';
import Getapp from 'sections/Getapp';
import Contact from 'sections/Contact';
import Footer from 'sections/Footer-classic';
import Modules from 'sections/Modules';


import { GlobalStyle } from 'sections/app.style';
import theme from 'theme/app/themeStyles';

const IndexPage = () => (
    <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Layout>
            <SEO title="MyCorp IO" />
            <Navigation />
            <Banner />
            {/* <About /> */}
            {/* <Features />
            <Integrations /> */}
            <Modules />
            {/* <AppScreens /> */}
            {/* <Counters /> */}
            <Pricing />
            {/* <Testimonial /> */}
            {/* <Faq /> */}
            {/* <Getapp /> */}
            {/* <News /> */}
            {/* <Contact /> */}
            <Footer />
        </Layout>
    </ThemeProvider>
)

export default IndexPage
