import React from "react";

import { Container, Row, Col } from "reusecore/Layout";
import SectionTitle from "reusecore/SectionTitle";
import Button from "reusecore/Button";
import VintageBox from "reusecore/VintageBox";
import Particle from "reusecore/Particle";

import { FaPlay, FaDownload } from "react-icons/fa";

import BannerSectionWrapper from "./banner.style";

const BannerDefault = () => {
    return (
        <BannerSectionWrapper>
            <Particle />
            <Container fullWidthSM>
                <Row>
                    <Col sm={7} md={12} lg={12}>
                        <SectionTitle
                            className="section-title"
                            leftAlign={true}
                            UniWidth="100%"
                        >
                            <h1>
                                Digital Essential Tools for Large and Small Enterprises
                            </h1>
                        </SectionTitle> 
                    </Col>
                </Row>
                <Row>
                    <Col sm={7} md={6} lg={7}>
                        <p>
                            <h1>
                                <span>MyCorp</span>
                            </h1><br /><br />
                            An integrated and routable essential digital tools for corporate and individuals, packaged in an intuitive easy to use, web and  mobile application.
                            <br /><br />
                            Built using modern and secure integration technologies (InstaCard) to allow modules within Apps to seamlessly inter-operate with other modules to facilitate an easy to use, simple, scalable and future-proof system, to allow users to create/route to-do, approval request, messaging, expense, timesheet, leave and survey tasks.

                            <br /><br />MyCorp is the only tool that you will ever need for your day-to-day corporate and personal tasks.
                        </p>
                        {/* <VintageBox right={true} vintageOne={true}>
                            <Button className="banner-btn one">
                                <FaDownload className="icon-left" />
                                    Try Now
                            </Button>
                            <Button className="banner-btn two">
                                <FaPlay className="icon-left" /> Watch Now
                            </Button>
                        </VintageBox> */}
                    </Col>
                </Row>
            </Container>
        </BannerSectionWrapper>
    );
};

export default BannerDefault;
