const theme = {
    black: '#333333',
    white: '#ffffff',
    primaryColor: '#8A57DE',
    primaryLightColor: '#EEE6FA',
    primaryLightColorTwo: '#f8f5fd',
    secondaryColor: '#FB7B81',
    secondaryLightColor: '#FFEBEC',
    tetriaryColor: '#E281CE',
    headingColor: '#2C0075',
    textColor: '#7A848E',
    shadowColor: 'rgba(0, 0, 0, 0.05)',
    shadowLightColor: '#E6E6E6',
    menuColor: '#1D316C',
    menuHoverColor: '#FB7B81'
};
export default theme;

